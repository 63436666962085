'use client';
import NextTopLoader from 'nextjs-toploader';
import * as NProgress from 'nprogress';
import React from 'react';

type Props = {};

export function stopLoader() {
    NProgress.done(true);
}

function PageProgress({}: Props) {
    return <NextTopLoader showSpinner={false} height={2} shadow={false} color={'#FF9E16'} />;
}

export default PageProgress;
