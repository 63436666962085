'use client';
import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect } from 'react';
import { GOOGLE_ANALYTICS } from '@/config/Config';
import { ensureGtag } from '@/config/Analytics';

type WindowWithDataLayer = Window & {
    dataLayer: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

export const GTM_ID = GOOGLE_ANALYTICS.MEASUREMENT_ID;

export const pageview = (url: string) => {
    if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push({
            event: 'pageview',
            page: url,
        });
    } else {
        console.log({
            event: 'pageview',
            page: url,
        });
    }
};

export default function PrefareGoogleAnalytics() {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        ensureGtag();
    }, []);

    useEffect(() => {
        if (pathname) {
            pageview(pathname);
        }
    }, [pathname, searchParams]);

    // if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    //     return null;
    // }

    return (
        <>
            <noscript>
                <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                />
            </noscript>
            <Script
                id="gtm-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${GOOGLE_ANALYTICS.MEASUREMENT_ID}');
  `,
                }}
            />
            <Script
                id={'prefare-setup-gtm'}
                dangerouslySetInnerHTML={{
                    __html: `
        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', '${GOOGLE_ANALYTICS.MEASUREMENT_ID}', {
            'debug_mode': ${GOOGLE_ANALYTICS.DEBUG_MODE},
            'traffic_type': '${GOOGLE_ANALYTICS.TRAFFIC_TYPE}',
        })`,
                }}
            />
        </>
    );
}
