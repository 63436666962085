import React, { MouseEventHandler, useEffect, useState } from 'react';
import { PrefareLogo } from '@/components/icons/custom-icons';
import useFeatureBadges from '@/hooks/useFeatureBadges';
import NewBadge from '@/components/badges/NewBadge';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { Menu } from 'lucide-react';
import SideMenu from '@/components/menu/SideMenu';
import { usePathname } from 'next/navigation';
import Link from 'next/link';

export type Props = { onBurgerClick?: MouseEventHandler; hideMobileNav?: boolean };
const ErrorViewHeader = (props: Props) => {
    const { showMobileBadge } = useFeatureBadges();
    const { hideMobileNav = false } = props;
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const pathname = usePathname();
    useEffect(() => {
        setSidebarOpen(false);
    }, [pathname]);

    return (
        <nav className="mb-6 px-4 lg:hidden" role="navigation" aria-label="main navigation">
            <div className="flex justify-between">
                <Link href="/" className="pt-4">
                    <PrefareLogo height="30px" />
                </Link>

                {!hideMobileNav && (
                    <Sheet open={sidebarOpen} onOpenChange={(open) => setSidebarOpen(open)}>
                        <SheetTrigger asChild>
                            {/*<div className="relative">*/}
                            <button
                                role="button"
                                className="relative"
                                aria-label="menu"
                                aria-expanded="false"
                                data-target="navbarBasicExample"
                            >
                                <Menu className={'h-5 w-5'} />
                                {showMobileBadge && <NewBadge className="absolute left-0 top-[4px]" />}
                            </button>
                        </SheetTrigger>
                        <SheetContent side={'right'}>
                            <SideMenu showLogo={true} className={'p-6'} />
                        </SheetContent>
                    </Sheet>
                )}
            </div>
        </nav>
    );
};

export default ErrorViewHeader;
