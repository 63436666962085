import { IconProp, library } from '@fortawesome/fontawesome-svg-core';

import {
    faAt,
    faBalanceScale,
    faBan,
    faBoxOpen,
    faCalendarWeek,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faCircle,
    faCog,
    faCogs,
    faComment,
    faCreditCard,
    faEnvelope,
    faExclamationTriangle,
    faExternalLinkAlt,
    faFileAlt,
    faFileInvoiceDollar,
    faGift,
    faHistory,
    faHome,
    faImages,
    faInfo,
    faInfoCircle,
    faLink,
    faLock,
    faMapMarkedAlt,
    faPhone,
    faPhoneAlt,
    faPlus,
    faQuestion,
    faSearch,
    faShoppingCart,
    faSignInAlt,
    faSignOutAlt,
    faStroopwafel,
    faTable,
    faTag,
    faTags,
    faTimes,
    faTrashAlt,
    faTruck,
    faUnlink,
    faUserCircle,
    faUserFriends,
    faUsers,
    faUtensils,
} from '@fortawesome/free-solid-svg-icons';

import {
    faCcAmex,
    faCcApplePay,
    faCcDinersClub,
    faCcDiscover,
    faCcJcb,
    faCcMastercard,
    faCcVisa,
} from '@fortawesome/free-brands-svg-icons';

export const setupIcons = () => {
    const iconList = [
        faStroopwafel,
        faCircle,
        faHome,
        faBalanceScale,
        faImages,
        faLink,
        faCog,
        faUnlink,
        faTruck,
        faBoxOpen,
        faCheck,
        faHistory,
        faUserCircle,
        faPlus,
        faUserFriends,
        faCogs,
        faLock,
        faQuestion,
        faCreditCard,
        faSignInAlt,
        faSignOutAlt,
        faAt,
        faTrashAlt,
        faPhoneAlt,
        faPhone,
        faChevronDown,
        faChevronUp,
        faCheckCircle,
        faExclamationTriangle,
        faBan,
        faUtensils,
        faExternalLinkAlt,
        faTimes,
        faCcAmex,
        faCcDiscover,
        faCcDinersClub,
        faCcVisa,
        faCcMastercard,
        faCcApplePay,
        faCcJcb,
        faEnvelope,
        faInfo,
        faInfoCircle,
        faFileInvoiceDollar,
        faMapMarkedAlt,
        faUsers,
        faSearch,
        faChevronLeft,
        faFileAlt,
        faCalendarWeek,
        faTable,
        faTags,
        faShoppingCart,
        faTag,
        faGift,
        faComment,
    ];

    library.add(...iconList);
};

export const getCreditCardCardIconName = (brand?: string | null): IconProp => {
    switch (brand?.toLowerCase()) {
        case 'amex':
        case 'american express':
            return ['fab', 'cc-amex'];
        case 'diners club':
        case 'diners':
            return ['fab', 'cc-diners-club'];
        case 'discover':
            return ['fab', 'cc-discover'];
        case 'jbc':
            return ['fab', 'cc-jcb'];
        case 'mastercard':
            return ['fab', 'cc-mastercard'];
        case 'visa':
            return ['fab', 'cc-visa'];
        case 'unknown':
        case 'unionpay':
        default:
            return 'credit-card';
    }
};
