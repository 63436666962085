'use client';
import React, { ReactNode } from 'react';
import { AUTH0 } from '@/config/Config';
import { Auth0Provider } from '@auth0/auth0-react';
import { SWRConfig } from 'swr';
import useApi from '@/hooks/useApi';
import { AuthContextProvider } from '@/components/contexts/AuthContext';
import ZendeskWidget from '@/components/ZendeskWidget';

export type Props = { children?: ReactNode };
const AuthenticatedWrappers = ({ children }: Props) => {
    const api = useApi();
    return (
        <SWRConfig value={{ fetcher: api.authenticatedFetch, revalidateOnFocus: false }}>
            <AuthContextProvider>
                <>
                    {children}
                    <ZendeskWidget />
                </>
            </AuthContextProvider>
        </SWRConfig>
    );
};

const ProviderWrapper = (props: Props) => {
    const { children } = props;
    return (
        <Auth0Provider
            domain={AUTH0.DOMAIN}
            clientId={AUTH0.CLIENT_ID}
            useRefreshTokens={false}
            cacheLocation={'localstorage'}
            authorizationParams={{
                redirect_uri: typeof window !== 'undefined' ? window.location.origin : undefined,
                scope: 'openid profile email offline_access user_metadata app_metadata',
                audience: AUTH0.AUDIENCE,
            }}
        >
            <AuthenticatedWrappers>{children}</AuthenticatedWrappers>
        </Auth0Provider>
    );
};

export default ProviderWrapper;
