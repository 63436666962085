'use client';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { isNotBlank, StateSetter } from '@/util/TypeUtil';
import LocalStorageService, { StorageKey } from '@/config/LocalStorageService';
import { useSearchParams } from 'next/navigation';

type ContextType = {
    /** String value of the referral code a user has on their session */
    sessionReferralCode: string | null;
    setSessionReferralCode: StateSetter<string | null>;
};

const AppGlobalContext = createContext<ContextType | null>(null);

export const useAppGlobalContext = () => {
    const context = useContext(AppGlobalContext);
    if (!context) {
        throw new Error('AppGlobalContext has not been defined');
    }
    return context;
};

type ProviderProps = { children: ReactNode };
export const AppGlobalContextProvider = ({ children }: ProviderProps) => {
    const searchParams = useSearchParams();
    const referralCodeParam = searchParams?.get('prc');

    const [sessionReferralCode, setSessionReferralCode] = useState<string | null>(null);

    useEffect(() => {
        const handleStorageEvent = (e: StorageEvent) => {
            if (e.key !== StorageKey.REFERRAL_CODE) {
                return;
            }

            if (isNotBlank(e.newValue)) {
                console.debug('setting new referral code to', e.newValue);
                setSessionReferralCode(e.newValue);
            }
        };

        window.addEventListener('storage', handleStorageEvent);

        return () => {
            window.removeEventListener('storage', handleStorageEvent);
        };
    }, []);

    useEffect(() => {
        if (isNotBlank(referralCodeParam)) {
            LocalStorageService.setItem(StorageKey.REFERRAL_CODE, referralCodeParam);
            setSessionReferralCode(referralCodeParam);
        } else {
            const storageCode = LocalStorageService.getItem(StorageKey.REFERRAL_CODE, null);
            if (storageCode !== referralCodeParam) {
                setSessionReferralCode(storageCode);
            }
        }
    }, [referralCodeParam]);

    useEffect(() => {
        if (sessionReferralCode) {
            LocalStorageService.setItem(StorageKey.REFERRAL_CODE, sessionReferralCode);
        } else {
            LocalStorageService.removeItem(StorageKey.REFERRAL_CODE);
        }
    }, [sessionReferralCode]);

    return (
        <AppGlobalContext.Provider
            value={{
                sessionReferralCode,
                setSessionReferralCode,
            }}
        >
            {children}
        </AppGlobalContext.Provider>
    );
};
