'use client';
import React, { useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import ErrorViewHeader from '@/components/structural/ErrorViewHeader';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@/components/ui/button';

type Props = {
    error: Error;
    componentStack: string | null;
    resetError: () => void;
    eventId: string | null;
    showHeader?: boolean;
};
const SentryErrorView = ({ error, componentStack, resetError, eventId, showHeader = false }: Props) => {
    const [showDetails, setShowDetails] = useState(false);
    const { user } = useAuth0();
    const handleSubmitFeedback = () => {
        Sentry.showReportDialog({
            eventId: eventId ?? undefined,
            title: "We're on it.",
            subtitle2: 'Any additional details you can provide will help us address the issue you ran into.',
            subtitle: 'Our team has been notified.',
            user: user
                ? {
                      email: user.email,
                  }
                : undefined,
        });
    };

    return (
        <div className="w-full">
            {showHeader && (
                <div className="mb-10">
                    <ErrorViewHeader />
                </div>
            )}
            <div className="flex flex-col items-center justify-center space-y-8">
                <h1 className="text-center text-lg font-bold">{"Whoops! Something isn't quite right."}</h1>

                <div className="mb-4 flex items-center space-x-4">
                    <Button onClick={handleSubmitFeedback}>Submit Feedback</Button>
                    <Button variant={'outlined'} onClick={resetError}>
                        Try again
                    </Button>
                </div>
                <div className="mb-4 bg-accent text-base text-red-500">
                    <div className="whitespace-pre-wrap bg-off-white p-4 font-mono text-red-500">
                        {error.message?.split('\n')[0] ?? 'No error details provided'}
                    </div>
                </div>
                <div>
                    <Button variant={'ghost'} onClick={() => setShowDetails(!showDetails)}>
                        {showDetails ? 'Hide error details' : 'Show error details'}
                    </Button>
                </div>

                {showDetails && (
                    <div className="mb-4 overflow-auto whitespace-pre-wrap bg-off-white p-4 font-mono text-xs">
                        {error.toString()}
                        {componentStack}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SentryErrorView;
