'use client';
import ProviderWrapper from '@/components/containers/ProviderWrapper';
import React, { ReactNode } from 'react';
import { AppGlobalContextProvider } from '@/components/contexts/AppGlobalContext';
import { setupIcons } from '@/components/icons/FontAwesome';
import * as Sentry from '@sentry/nextjs';
import SentryErrorView from '@/components/SentryErrorView';
type Props = { children: ReactNode };
setupIcons();
export function AppProviders({ children }: Props) {
    return (
        <ProviderWrapper>
            <Sentry.ErrorBoundary
                beforeCapture={(scope) => {
                    scope.setTag('error-boundary-location', 'AppProviders');
                }}
                fallback={(props) => {
                    return <SentryErrorView showHeader={false} {...props} />;
                }}
            >
                <AppGlobalContextProvider>{children}</AppGlobalContextProvider>
            </Sentry.ErrorBoundary>
        </ProviderWrapper>
    );
}
